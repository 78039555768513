.rgba-gradient {
  background-color: #eee;
}

.header {
  height: 100vh;
  color: #fff;
}

.dev-img {
  animation: move 2.5s ease-in-out infinite;
}

@keyframes move {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(40px);
    transform: translateX(20px);
  }
}
